<template>
<div class="kyc-approval-common-inner-content-wrapper"
        v-for="(nominee, index) in nomineeDetails"
        :key="index"
      >
        <div class="client-details" v-if="showNomineeData">
          <div class="client-details-key-with-wrapper">
            <div style="font-weight: bolder;">Nominees Added</div>
            <div class="client-details-key-text">Name:</div>
            <div class="client-details-key-text">Mobile No:</div>
            <div class="client-details-key-text">Relation:</div>
            <div class="client-details-key-text">DOB:</div>
            <div class="client-details-key-text">Share:</div>
            <div class="client-details-key-text">Email:</div>
            <div class="client-details-key-text">Address Line 1:</div>
            <div class="client-details-key-text">Address Line 2:</div>
            <div class="client-details-key-text">City:</div>
            <div class="client-details-key-text">State:</div>
            <div class="client-details-key-text">Country:</div>
            <div class="client-details-key-text">Pincode:</div>
            <div class="client-details-key-text">File:</div>
            <div class="client-details-key-text">File Password:</div>
          </div>
          <div class="client-details-response-with-wrapper">
            <br>
            <div class="client-details-response-text">
              {{ nominee.name ? nominee.name : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.mobile ? nominee.mobile : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.relation ? nominee.relation : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.dob ? nominee.dob : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.share ? nominee.share : "-" }}%
            </div>
            <div class="client-details-response-text">
              {{ nominee.email ? nominee.email : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.address1 ? nominee.address1 : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.address2 ? nominee.address2 : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.city ? nominee.city : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.state ? nominee.state : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.country ? nominee.country : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.pin ? nominee.pin : "-" }}
            </div>
            <div class="account-statement-file-with-download">
          <div>
            <file-pdf-outlined
              :style="{
                fontSize: '12px',
                marginRight: '10px',
                color: 'red',
              }"
            />
          </div>
          <div style="font-size: 12px;">{{ nominee.document.name }}</div>
          <div style="margin-left: 50px">
            <a :href="nominee.document.url" target="_blank"
              ><download-outlined
                :style="{
                  fontSize: '20px',
                  color: '#CC4B4C',
                  cursor: 'pointer',
                }"
            /></a>
          </div>
        </div>
            <div class="client-details-response-text">
              {{ nominee.password ? nominee.password : "Not Password Protected" }}
            </div>
          </div>
        </div>
      </div>
      <div style="font-weight: bolder; max-width: 540px; margin: 0 auto; max-height: 538px;
      text-align: center;" v-if="!showNomineeData">No Nominee Added</div>
      <div v-if="!showNomineeData" class="add-nominee-main-wrapper">
    <a-form
      ref='formRef'
      name='dynamic_form_nest_item'
      :model='dynamicValidateForm'
      @finish='onFinish'
    >
      <a-row style='display: unset'>
        <a-col>
          <div>
            <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
            <div>
              <div class='add-nominee-switch'>
                <span class='add-nominee-switch-text'>
                  Would you like to add nominee details?</span
                >
                <a-switch
                  size='small'
                  style='float: right; margin-top: 10px'
                  v-model:checked='nomineeRequired'
                  @change='onAddNominee'
                  checked-children="Yes" un-checked-children="No"
                  :disabled='disable'
                />
              </div>
              <div v-if='addNominee' class='add-nominee-top-text'>
                You can add upto 3 nominees
              </div>
              <div v-if='addNominee'>
                <a-space
                  v-for='(nominee, index) in dynamicValidateForm.nominees'
                  :key='nominee.id'
                  style='display: grid'
                >
                  <div style='display: flex; margin-top: 30px'>
                    <div style='width: 100%; margin-right: 10px'>
                      <div padding-bottom='10px'>
                      <div style="border-radius: 5px; background-color: #D1DBFD;
                      width: 20%; color: #0D44F5;">
                        <div>
                        Nominee {{ index + 1 }}
                        </div>
                      </div>
                    </div>
                      <div class='add-nominee-inputs-wrapper'>
                        <div style='width: 100%; padding-top: 10px;'>
                          <a-form-item
                            :name="['nominees', index, 'name']"
                            :rules='{
                              required: true,
                              message: "Please enter Name",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.name'
                              placeholder='Nominee Name'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                      </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style='width: 100%'>
                          <a-form-item
                            :name="['nominees', index, 'relation']"
                            :rules='{
                              required: true,
                              message: "Please select relation",
                            }'
                          >
                            <!-- <a-input
                              class='input-field'
                              v-model:value='nominee.relation'
                              placeholder='Relationship'
                            >
                            </a-input> -->
                            <a-select
                              class='select-field'
                              v-model:value='nominee.relation'
                              placeholder='Relationship'
                              :options='relationshipOptions'
                              style='text-align: initial'
                              :disabled='disable'
                            ></a-select>
                          </a-form-item>
                        </div>
                      </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style='width: 100%; margin-right: 10px'>
                          <a-form-item
                            :name="['nominees', index, 'mobNo']"
                            :rules='{
                              required: true,
                              message: "Please enter Mobile No",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.mobNo'
                              placeholder='Nominee Mob No.'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                        <div style='width: 100%'>
                          <a-form-item
                            :name="['nominees', index, 'email']"
                            :rules='{
                              required: true,
                              message: "Please enter Email Address",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.email'
                              placeholder='Nominee Email Address'
                              type='text'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                      </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style='width: 100%; margin-right: 10px'>
                          <a-form-item
                            :name="['nominees', index, 'addressLine1']"
                            :rules='{
                              required: true,
                              message: "Please enter Flat, Building",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.addressLine1'
                              placeholder='Flat, Building'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                        <div style='width: 100%'>
                          <a-form-item
                            :name="['nominees', index, 'addressLine2']"
                            :rules='{
                              required: true,
                              message: "Please enter Street, Area, Village",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.addressLine2'
                              placeholder='Street, Area, Village'
                              type='text'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                      </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style='width: 100%; margin-right: 10px'>
                          <a-form-item
                            :name="['nominees', index, 'city']"
                            :rules='{
                              required: true,
                              message: "Please enter City",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.city'
                              placeholder='City'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                        <div style='width: 100%'>
                          <a-form-item
                            :name="['nominees', index, 'state']"
                            :rules='{
                              required: true,
                              message: "Please enter state",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.state'
                              placeholder='State'
                              type='text'
                              :disabled='disable'
                              :max= 100
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                      </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style='width: 100%; margin-right: 10px'>
                          <a-form-item
                            :name="['nominees', index, 'country']"
                            :rules='{
                              required: true,
                              message: "Please enter Country",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.country'
                              placeholder='Country'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                        <div style='width: 100%'>
                          <a-form-item
                            :name="['nominees', index, 'pincode']"
                            :rules='{
                              required: true,
                              message: "Please enter Pincode",
                            }'
                          >
                            <a-input-number
                              class='input-field'
                              v-model:value='nominee.pincode'
                              placeholder='Pincode'
                              type='number'
                              :disabled='disable'
                            >
                            </a-input-number>
                          </a-form-item>
                        </div>
                      </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style='width: 100%; margin-right: 10px'>
                          <a-form-item
                            :name="['nominees', index, 'dob']"
                            :rules='{
                              required: true,
                              message: "Please enter DOB",
                            }'
                          >
                            <a-input
                              class='input-field'
                              v-model:value='nominee.dob'
                              placeholder='Nominee DOB'
                              type='date'
                              :disabled='disable'
                            >
                            </a-input>
                          </a-form-item>
                        </div>
                        <div style='width: 100%'>
                          <a-form-item
                            :name="['nominees', index, 'share']"
                            :rules='{
                              required: true,
                              message: "Please enter share",
                            }'
                          >
                            <a-input-number
                              class='input-field'
                              v-model:value='nominee.share'
                              placeholder='Nominee Share (%)'
                              type='number'
                              :disabled='disable'
                              :max= 100
                            >
                            </a-input-number>
                          </a-form-item>
                        </div>
                      </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style="width: 100%">
                          <div class='add-nominee-upload-btn'>
                            <a-upload
                            v-model:file-list='nominee.fileList'
                            accept='image/png, image/jpeg, image/jpg'
                            :max-Count='1'
                            method="GET"
                            @change="handleFileChange"
                            >
                            <a-button :disabled='disable'
                            style='width: 100%; height: 44px; border: 1px solid #79a89b'>
                            <span style='float: left'> Upload Nominee's Proof of Identity </span>
                            <cloud-upload-outlined style='font-size: 20px; float: right' />
                          </a-button>
                        </a-upload>
                      </div>
                    </div>
                  </div>
                      <div class='nominee-details-inputs-wrapper'>
                        <div style='width: 100%'>
                          <a-form-item
                            :name="['nominees', index, 'password']"
                            :rules='{
                              required: false,
                            }'
                          >
                          <a-input-password
                      class='input-field'
                      v-model:value='nominee.password'
                      placeholder='Enter Password, if the file is protected with one'
                      :disabled='disable'
                    />
                          </a-form-item>
                        </div>
                      </div>
                      <div class='add-nominee-password-bottom-text'>
                  *Accepted Documents:PAN / Aadhar / Driver's License / Voter-ID / Birth Certificate
                </div>
                    </div>
                    <div style='margin-left: auto; padding-top: 12px'>
                      <MinusCircleOutlined @click='removeUser(nominee)'
                      :style="dynamicValidateForm.nominees.length == 1 ? 'display:none' : ''"/>
                    </div>
                  </div>
                </a-space>
                <div
                  :style="dynamicValidateForm.nominees.length > 2 ? 'display:none' : ''"
                  class='add-new-nominee-btn-wrapper'
                >
                  <a-button  type='primary'
                   @click='addUser'
                    >+ Add New Nominee</a-button
                  >
                </div>
              </div>
              <div class='add-nominee-btn-wrapper'>
                <a-button v-if="addNominee" type='primary' @click='onFinish'
                :loading="loading">Save</a-button>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
  </template>
<script>
/* eslint-disable */
  // import { useRouter } from 'vue-router';
  import { onMounted, ref, reactive } from 'vue';
  import { FilePdfOutlined, DownloadOutlined, MinusCircleOutlined, CloudUploadOutlined, UserOutlined } from "@ant-design/icons-vue";
  import { useStore } from 'vuex';
  import { message } from 'ant-design-vue';
  import { ESIGN_LOGO, APP_ENV } from '../../../constants';
  import services from '../../../services/apis';
  import Operations from '../../../utils/operations';
  
  export default {
    components: {
      FilePdfOutlined,
      DownloadOutlined,
      MinusCircleOutlined,
      UserOutlined,
    },
    setup() {
      const store = useStore();
      const details = reactive({
        name: '',
        clientId: '',
    })
      const ddpiInformation = `As per new SEBI regulation, it is mandatory for new clients to send this DDPI document duly signed, 
        and sent to the DP address physically to initiate DEMAT account opening process.`;
      const ddpi = ref({
        information: ddpiInformation,
        courierAddress: '',
        authorizedPerson: '',
        document: {
          name: '',
          link: '',
        },
      });
    const disable = ref(false);
    const loading = ref(false);
    const responseStage = ref('')
    const nomineeRequired = ref(false);
    const nomineeDetails = ref([]);
    const addNominee = ref(false);
    const showNomineeData = ref(true)
    const dynamicValidateForm = reactive({
      nominees: [],
    });
    const relationshipOptions = ref([
      {
        value: 'FATHER',
        label: 'Father',
      },
      {
        value: 'MOTHER',
        label: 'Mother',
      },
      {
        value: 'SISTER',
        label: 'Sister',
      },
      {
        value: 'BROTHER',
        label: 'Brother',
      },
      {
        value: 'SPOUSE',
        label: 'Spouse',
      },
      {
        value: 'SON',
        label: 'Son',
      },
      {
        value: 'DAUGHTER',
        label: 'Daughter',
      },
    ]);
    const removeUser = (item) => {
      const index = dynamicValidateForm.nominees.indexOf(item);

      if (index !== -1) {
        dynamicValidateForm.nominees.splice(index, 1);
      }
    };
    const addUser = () => {
      dynamicValidateForm.nominees.push({
        name: '',
        relation: undefined,
        dob: '',
        mobNo:'',
        email:'',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        share: null,
        id: Date.now(),
        fileList: [],
        password: '',
      });
    };
      onMounted(() => {
        window.scrollTo(0, 0);
        services
          .getStages()
          .then((response) => {
              details.name = response.data.data.prefill.name;
              details.clientId = response.data.data.prefill.clientId;
              if(typeof(response.data.data.prefill?.nominees) !== undefined &&
              response.data.data?.prefill?.nominees.length > 0) {
                showNomineeData.value = true;
                addNominee.value = true;
                nomineeDetails.value = response.data.data.prefill.nominees
              }
              else {
                showNomineeData.value = false;
              }
              console.log(showNomineeData.value)
          })
          .catch(() => {});
      });
      const onAddNominee = () => {
      if (nomineeRequired.value === true) {
        addNominee.value = true;
      } else {
        addNominee.value = false;
      }
    };
    const formRef = ref();
    
    const handleFileChange = (info) => {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
    const options = {
      environment: APP_ENV === 'production' ? 'production' : 'sandbox',
      callback: (response) => {
        console.log(response);
        let payload = {};
        if (Object.prototype.hasOwnProperty.call(response, 'txn_id')) {
          payload = {
            status: 'SUCCESS',
            message: response.message,
          };
        } else if (Object.prototype.hasOwnProperty.call(response, 'error_code')) {
          payload = {
            status: 'FAILED',
            message: response.message,
          };
        }
        services
          .aadhaarEsignStatus(payload)
          .then((aadharStatusResponse) => {
            store.dispatch(
              'onboardingStore/updateStage',
              aadharStatusResponse.data.data.stage,
            );
          })
          .catch(() => {
          });
      },
      is_redirection_approach: false,
      logo: ESIGN_LOGO,
      is_iframe: true,
      redirect_url: '',
    };

    const onFinish = async() => {
      if (!nomineeRequired.value) {
        try {

          loading.value = true;
          disable.value = true;
          const formData = new FormData();
          formData.append('nomineeRequired', nomineeRequired.value)
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          const response = await services.addNominee(formData, config)
          loading.value = false
          store.dispatch('onboardingStore/updateStage', response.data.data.stage);
        }
        catch(e) {
          loading.value = false;
          disable.value = false;
          message.error(e.response.data.error.name, 2);
        };
      } else {
        const formData = new FormData();
        const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
        const data = {
          nomineeRequired: nomineeRequired.value,
          nominees: dynamicValidateForm.nominees,
        };
        const shareTotal = data.nominees.reduce((initialValue, itr) => initialValue += itr.share, 0);
        if (shareTotal !== 100) {;
          const message = 'Validation error';
          const description = 'Total distribution of shares among nominee should be 100%';
          Operations.notificationError(message, description);
        } else {
          try {

            loading.value = true;
            disable.value = true;
            for(const nominee of dynamicValidateForm.nominees) {
              formData.append('documentType', "NOMINEE")
              formData.append('nomineeRequired', nomineeRequired.value)
              formData.append('name', nominee.name)
              formData.append('email', nominee.email)
              formData.append('address1', nominee.addressLine1)
              formData.append('address2', nominee.addressLine2)
              formData.append('mobile', nominee.mobNo)
              formData.append('relation', nominee.relation)
              formData.append('share', nominee.share)
              formData.append('dob', nominee.dob)
              formData.append('state', nominee.state)
              formData.append('country', nominee.country)
              formData.append('city', nominee.city)
              formData.append('file', nominee.fileList[0].originFileObj)
              formData.append('pin', nominee.pincode)
              formData.append('password', nominee.password)
              console.log('entered nominees',nominee)
              console.log('form data details ',formData)
              const response = await services.addNominee(formData, config)
              formData.delete('documentType')
              formData.delete('nomineeRequired')
              formData.delete('name')
              formData.delete('email')
              formData.delete('address1')
              formData.delete('address2')
              formData.delete('mobile')
              formData.delete('relation')
              formData.delete('share')
              formData.delete('dob')
              formData.delete('state')
              formData.delete('country')
              formData.delete('city')
              formData.delete('file')
              formData.delete('pin')
              formData.delete('password')
              
        };
          loading.value = false;
          const message = null;
      const description = 'Please wait while prepare your document for digital signature';
      Operations.notificationInfo(message, description);
      disable.value = true;
      services
        .aadhaarEsign({})
        .then((response) => {
          /**
           * eSignDocumentIds contains a list of document Ids to be signed by a client.
           * A common/default use case is:
           * - 1st document is the account opening document
           * - 2nd document is CVL KRA document (if the client has never done his KRA)
           */
          const { mobile, digioClientId, eSignDocumentIds } = response.data.data;
          const DigioInit = new window.Digio(options);
          DigioInit.init();

          if (eSignDocumentIds) {
            DigioInit.submit(eSignDocumentIds, mobile, digioClientId);
          } else {
            // Fallback to the older method of extracting documentId from eSign response.
            // This implies APIs have stopped supporting multiple documents signing.
            const { documentId } = response.data.data.eSign;
            DigioInit.submit(documentId, mobile, digioClientId);
          }

          disable.value = false;
        })
      }
        catch(e) {
          const systemError = Operations.axiosErrorHandler(error);
          const errorMessage = systemError.status;
          const errorDescription = systemError.message
            ? systemError.message
            : '';
          Operations.notificationError(errorMessage, errorDescription);
          disable.value = false;
            loading.value = false;
              disable.value = false;
              message.error(e.response.data.error.name, 2);
            };
        } 
      }
    };

      return {
        ddpi,
        details: details,
        nomineeDetails,
        nomineeRequired,
        onAddNominee,
        addNominee,
        formRef,
        dynamicValidateForm: dynamicValidateForm,
        onFinish,
        removeUser,
        addUser,
        relationshipOptions,
        handleFileChange,
        responseStage,
        showNomineeData,
      };
    },
  };
  </script>
  
  <style lang='scss'>
  @import '../styles/NomineeDetails.scss';
  </style>