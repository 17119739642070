<template>
  <div>
    <div class="header">
      <div class="heading">ACCOUNT</div>
    </div>
    <div class="user">
      <div style="padding-left: 20px;">
        <div>
          <UserOutlined :style="{
            fontSize: '40px',
            color: 'black', margin: auto,
          }" /><br>
          Name <br><strong>{{ details.name }}</strong><br>
        </div>
      </div>
      <div class="Left-Navigation" style="width=200px">
        <div class="tabs">
          <a-tabs tabPosition="left" v-model:activeKey="activeKey" :animated="false">
            <a-tab-pane key="1" tab="Personal">
              <div class="Profile-details">
                <!-- <PersonalDetails /> -->
                <div class="Personal-details">
                  <div> <br> Client ID <br>
                    <strong> {{ details.clientId ? details.clientId : '-' }} </strong>
                  </div>
                  <div style="font-size: 30px; padding-top: 40px;">Personal</div>
                  <div class="line-separator" style="margin-top: 10px;"></div>
                  <div style="width: 100%;"> <br> mobile No <br>
                    <strong> {{ details.mobile ? details.mobile : '-' }} </strong>
                    <div> <br> PAN <br>
                      <strong> {{ details.pan ? details.pan : '-' }} </strong>
                    </div>
                  </div>
                </div>
                <div>
                  <div style="margin-left: 150px;"> <br> CKYC No <br>
                    <strong> {{ details.ckyc ? details.ckyc : '-' }} </strong>
                  </div>
                  <div class="line-separator" style="margin-top: 98px;"></div>
                  <div style="width: 100%; padding-left: 150px;"> <br> email ID <br>
                    <strong> {{ details.email ? details.email : '-' }} </strong>
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Nominee">
              <div class="Nominee-details">
                <NomineeDetails />
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
/* eslint-disable */
// import { useRouter } from 'vue-router';
import { onMounted, ref, reactive } from 'vue';
import { FilePdfOutlined, DownloadOutlined, MinusCircleOutlined, CloudUploadOutlined, UserOutlined } from "@ant-design/icons-vue";
import { useStore } from 'vuex';
import services from '../../../services/apis';
import NomineeDetails from './NomineeDetails.vue';
import PersonalDetails from './personalDetails.vue';
export default {
  components: {
    FilePdfOutlined,
    DownloadOutlined,
    MinusCircleOutlined,
    UserOutlined,
    NomineeDetails,
    PersonalDetails,
  },
  setup() {
    const store = useStore();
    const details = reactive({
      name: '',
      clientId: '',
      email: '',
      mobile: '',
      ckyc: '',
      pan: '',
    })
    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          details.name = response.data.data.prefill.name;
          details.clientId = response.data.data.prefill.clientId;
          details.email = response.data.data.prefill.email;
          details.mobile = response.data.data.prefill.mobile;
          details.ckyc = response.data.data.prefill.ckyc;
          details.pan = response.data.data.prefill.pan;
        })
        .catch(() => { });
    });
    return {
      details: details,
    };
  },
};
</script>
  
<style lang='scss'>
@import '../styles/approvedProfile.scss';
</style>