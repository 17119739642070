<template>
    <div class="details">
  <div style="display: flex;" class="Personal-details">
    <div> <br> Client ID <br>
      <strong> {{ details.clientId ? details.clientId : '-' }} </strong>
    </div>
    <div> <br> CKYC No <br>
      <strong> {{ details.ckyc ? details.ckyc : '-' }} </strong>
    </div>
  </div>
</div>
    <div>
      <div style="width: 100%;"> <br> mobile No <br>
        <strong> {{ details.mobile ? details.mobile : '-' }} </strong>
      </div>
      <div style="width: 100%; padding-left: 150px;"> <br> email ID <br>
        <strong> {{ details.email ? details.email : '-' }} </strong>
      </div>
    </div>
</template>
<script>
/* eslint-disable */
// import { useRouter } from 'vue-router';
import { onMounted, ref, reactive } from 'vue';
import { UserOutlined } from "@ant-design/icons-vue";
import { useStore } from 'vuex';
import services from '../../../services/apis';

export default {
  setup() {
    const store = useStore();
    const details = reactive({
      name: '',
      clientId: '',
      email: '',
      mobile: '',
      ckyc: '',
    })
    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          details.name = response.data.data.prefill.name;
          details.clientId = response.data.data.prefill.clientId;
          details.email = response.data.data.prefill.email;
          details.mobile = response.data.data.prefill.mobile;
          details.ckyc = response.data.data.prefill.ckyc;
        })
        .catch(() => { });
    });
    return {
      details: details,
    };
  },
};
</script>
    
<style lang='scss'>@import '../styles/approvedProfile.scss';</style>
    